<template>
  <div id="autoServices" class="container-fluid p-0">
    <h1 class="text-center mb-4">Auto Services</h1>
    <meta name="description"
      content="Welcome to our auto service center! We offer a wide range of top-notch auto services, performed by our expert technicians with years of experience. From oil changes and tire rotations to brake inspections and exhaust system repairs, we've got you covered. Book your service today and enjoy a reliable and safe ride!" />
    <div class="service-list">
      <div class="service px-5" v-for="(service, index) in services" :key="index">
        <div class="service-icon">
          <i :class="service.icon"></i>
        </div>
        <h2>{{ service.name }}</h2>
        <p>{{ service.description }}</p>
      </div>
    </div>
    <img
      src="https://images.unsplash.com/photo-1531986627054-7f294d095acd?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      class="bottom-image" alt="Unique Auto Service Image" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          name: 'Oil Change & Lubrication',
          description: 'Keep your engine running smoothly with our comprehensive oil change services. We use high-quality oil to ensure optimal performance and longevity.',
          icon: 'fas fa-wrench'
        },
        {
          name: 'Tire Rotation & Replacement',
          description: 'Proper tire rotation extends tire life and improves safety. Trust our experts to keep your tires in good condition.',
          icon: 'mdi mdi-car-tire-alert'
        },
        {
          name: 'Battery Maintenance & Replacement',
          description: 'We test and charge your battery to ensure it starts your vehicle reliably. Avoid getting stranded, stay ahead with our battery maintenance.',
          icon: 'fas fa-battery-full'
        },
        {
          name: 'Brake Inspection & Maintenance',
          description: 'Smooth stops are essential. Our brake inspection service will identify any issues and provide necessary repairs to keep you safe on the road.',
          icon: 'mdi mdi-car-brake-abs'
        },
        {
          name: 'Exhaust System Repair & Maintenance',
          description: "A well-functioning exhaust system is vital. We perform thorough repairs and maintenance to keep your vehicle quiet, efficient, and emissions compliant.",
          icon: 'fas fa-car-side'
        },
        {
          name: 'Air Filter Replacement',
          description: "A clean air filter improves engine performance and fuel efficiency. Don't let a dirty filter drag down your ride.",
          icon: 'fas fa-filter'
        },
      ]
    };
  },
  mounted() {
    this.animateServices();
  },
  methods: {
    animateServices() {
      const services = document.querySelectorAll('.service');
      services.forEach((service, index) => {
        setTimeout(() => { service.classList.add('visible'); }, index * 200);     // Stagger the animation by 200ms per service
      });
    }
  }
};
</script>

<style scoped>
.service-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: calc(33.33% - 40px);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}

.service.visible {
  opacity: 1;
  transform: translateY(0);
}

.service-icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.service:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Media Queries for Responsiveness */
@media (max-width: 991px) {
  .service {
    width: calc(50% - 40px);
  }
}

@media (max-width: 768px) {
  .service {
    width: 100%;
  }
}

.bottom-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}
</style>