<template>
  <div class="container ">
    <h1 class="mt-2">How Can We Help?</h1>
    <section class="row justify-content-evenly  ">
      <div class="col-md-5 hide-logo">
        <div>
          <img class="img-fluid logo-size mt-5" src="../assets/logo.png" alt="">
        </div>
        <h1 class="text-center">Steele Auto Repair</h1>
      </div>
      <div class="col-md-5 text-start d-flex justify-content-evenly align-items-start flex-column">
        <div>
          <h3>Phone</h3>
          <h5><span>Bryan: </span>(760)717-7489</h5>
          <h5><span>Liam: </span>(208)890-1907</h5>
        </div>
        <div>
          <h3>E-mail</h3>
          <h5>bryan.vassar@ironshieldtech.com</h5>
          <h5>liam.smith@ironshieldtech.com</h5>
        </div>
      </div>
      <!-- CONTACT FORM -->
      <!-- <div class=" col-lg-7  ">
          <form>

            <input v-model="editable.name" type="text" class="form-control input-bg mt-1 mb-3 input-control input-size "
              id="formGroupExampleInput" maxlength="50" placeholder="Name">
            <input v-model="editable.phone" type="text" class="form-control input-bg mb-3 input-control "
              id="formGroupExampleInput2" maxlength="50" placeholder="Phone">
            <input v-model="editable.email" type="text" class="form-control input-bg mb-3 input-control "
              id="formGroupExampleInput3" maxlength="50" placeholder="Email">
            <select v-model="editable.type" class=" form-select input-bg mb-3 input-control "
              id="formGroupExampleInput4">
              <option selected>Type</option>
              <option value="1">Business</option>
              <option value="2">Portfolio</option>
              <option value="3">Personal</option>
            </select>
            <textarea v-model="editable.description" class="input-bg  form-control mb-3 input-control "
              id="formGroupExampleInput5" placeholder="Brief Description" rows="10" maxlength="450"></textarea>
            <div class="mb-3 ">
              <button class="me-2 btn text-dark button-style-two" data-bs-dismiss="modal"
                @click.prevent="sendMail">Submit</button>
              <button class="btn text-dark button-style-two" data-bs-dismiss="modal">Close</button>
            </div>
          </form>
        </div> -->
    </section>
  </div>
</template>


<script>
import { ref } from "vue";


export default {
  setup() {
    const editable = ref({});






    return {
      editable,
      sendMail() {
        // eslint-disable-next-line no-undef
        Email.send({
          Host: "	smtp.elasticemail.com",
          Username: "cpats2@gmail.com",
          Password: "C9A9CD55EE2E36188568C6DD6AD1D33B3258",
          To: "liamj.smith@hotmail.com",
          From: "cpats2@gmail.com",
          Subject: "this is a test",
          Body: editable.value
        })
        editable.value = {}
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.button-style-two {
  border: 2px solid #08494B;
  z-index: 3;
}

.input-bg {
  background-color: #dedddb;
  border: 1px solid #303c4c;
}

.select-text {
  color: #2125296b !important;
}


@media only screen and (min-width:991px) {
  .input-control {
    width: 95%;
  }
}

.logo-size {
  max-width: 200px;
  max-height: 400px;
}

// NOTE hides logo when screen size is bellow 991px was having layout issues at that size
@media only screen and (min-width:0px) and (max-width:991px) {
  .hide-logo {
    display: none;
  }
}

.container {
  padding: 0;
  margin: 0 auto;
}
</style>
