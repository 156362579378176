<template>
  <header class="sticky-top">
    <Navbar />
  </header>
  <main class="bg-color">
    <router-view />
  </main>

  <Footer></Footer>

  <Modal id="contactForm">
    <ContactForm />
  </Modal>
</template>
<script>

import Navbar from "./components/Navbar.vue";
import Modal from "./components/Modal.vue"
import ContactForm from "./components/ContactForm.vue"
import Footer from "./components/Footer.vue";
export default {
  setup() {
    return {

    }
  },
  components: { Navbar, Modal, ContactForm, Footer }

}
</script>

<style>
#app {
  font-family: 'Libre Franklin';
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}

header {
  margin: 0;
  padding: 0;
  height: 16vh;
}

main {
  min-height: 80vh;
}

footer {
  display: grid;
  place-content: center;
  height: 15vh;
}

.pointer {
  cursor: pointer;
}

.default {
  cursor: default;
}

.bg-color {
  background-color: #fbffff;
}
</style>
