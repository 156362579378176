import { createRouter, createWebHistory } from "vue-router";
import Home from "@/pages/HomePage.vue";
import DieselServices from '@/pages/DieselServices.vue';
import AutoServices from '@/pages/AutoServices.vue';
import About from '@/pages/About.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/diesel-services',
    name: 'DieselServices',
    component: DieselServices
  },
  {
    path: '/auto-services',
    name: 'AutoServices',
    component: AutoServices
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
];

export const router = createRouter({
  history: createWebHistory(),
  routes
})