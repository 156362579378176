<template>
  <div id="home" class="container-fluid home p-0">
    <section class="row services">
      <!-- Diesel Services Section -->
       
      <div class="col-md-6">
        <div class="door-wrapper shadow-lg">
          <div class="door-front">
            <img src="../assets/Steele_Diesel_Door.png" alt="Mechanic's Shop Door" class="door-image" />
          </div>
          <div class="garage-door-content">
            <div class="services-container">
              <div class="service">
                <div class="service-icon">
                  <i class="fas fa-cog"></i>
                </div>
                <h3>Engine Overhaul</h3>
                <p>Complete engine overhaul ensuring smooth and efficient performance.</p>
              </div>
              <div class="service">
                <div class="service-icon">
                  <i class="fas fa-tachometer-alt"></i>
                </div>
                <h3>Turbocharger Repair</h3>
                <p>Specialized turbocharger repair and replacement services.</p>
              </div>
              <div class="service">
                <div class="service-icon">
                  <i class="fas fa-gas-pump"></i>
                </div>
                <h3>Fuel System Maintenance</h3>
                <p>Maintenance for a clean and efficient fuel system.</p>
              </div>
              <div class="service">
                <div class="service-icon">
                  <i class="fas fa-smog"></i>
                </div>
                <h3>DEF Services</h3>
                <p>DEF system maintenance ensuring compliance with emissions standards.</p>
              </div>
              <div class="service">
                <div class="service-icon">
                  <i class="fas fa-laptop-code"></i>
                </div>
                <h3>Electronic Diagnostics</h3>
                <p>Advanced diagnostic tools for quick issue identification and repair.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="p-4">
          <h2>The Diesel Shop</h2>
        </div>
      </div>
      <!-- Auto Services Section -->
      <div class="col-md-6">
        <div class="door-wrapper shadow-lg">
          <div class="door-front">
            <img src="../assets/Steele_Auto_Repair_Door.png" alt="Mechanic's Shop Door" class="door-image" />
          </div>
          <div class="garage-door-content">
            <div class="services-container">
              <div class="service">
                <div class="service-icon">
                  <i class="fas fa-wrench"></i>
                </div>
                <h3>Oil Change & Lubrication</h3>
                <p>Keep your engine running smoothly with our comprehensive oil change services. We use high-quality oil
                  to ensure optimal performance and longevity.</p>
              </div>
              <div class="service">
                <div class="service-icon">
                  <i class="mdi mdi-car-tire-alert"></i>
                </div>
                <h3>Tire Rotation & Replacement</h3>
                <p>Proper tire rotation extends tire life and improves safety. Trust our experts to keep your tires in
                  good condition.</p>
              </div>
              <div class="service">
                <div class="service-icon">
                  <i class="fas fa-battery-full"></i>
                </div>
                <h3>Battery Maintenance & Replacement</h3>
                <p>We test and charge your battery to ensure it starts your vehicle reliably. Avoid getting stranded,
                  stay ahead with our battery maintenance.</p>
              </div>
              <div class="service">
                <div class="service-icon">
                  <i class="mdi mdi-car-brake-abs"></i>
                </div>
                <h3>Brake Inspection & Maintenance</h3>
                <p>Smooth stops are essential. Our brake inspection service will identify any issues and provide
                  necessary repairs to keep you safe on the road.</p>
              </div>
              <div class="service">
                <div class="service-icon">
                  <i class="fas fa-car-side"></i>
                </div>
                <h3>Exhaust System Repair & Maintenance</h3>
                <p>A well-functioning exhaust system is vital. We perform thorough repairs and maintenance to keep your
                  vehicle quiet, efficient, and emissions compliant.</p>
              </div>
              <div class="service">
                <div class="service-icon">
                  <i class="fas fa-filter"></i>
                </div>
                <h3>Air Filter Replacement</h3>
                <p>A clean air filter improves engine performance and fuel efficiency. Don't let a dirty filter drag
                  down your ride.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="p-4">
          <h2>The Auto Shop</h2>
        </div>
      </div>
      <div class="contact-info pt-5">
        <h2>Contact Us</h2>
        <h3>Email: steeleservices50@gmail.com</h3>
        <h3>Phone: (208)871-2742</h3>
        <h3>Address: 104 E 44th Street, Ste.102</h3>
        <h3>Garden City, ID 83714</h3>
      </div>
    </section>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.home {
  overflow: hidden;
}

.garage-title {
  z-index: -1;
}

.door-wrapper {
  position: relative;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  perspective: 1000px;
  box-shadow: #000000;
}

.door-front {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.8s ease;
  z-index: 1;
}

.door-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.garage-door-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  /* Optional: to see the content better */
  border-radius: 10px;
  overflow: hidden;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.service {
  flex: 1 1 45%;
  max-width: 45%;
  text-align: center;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.service-icon {
  font-size: 20px;
  color: #007bff;
  margin-bottom: 5px;
}

h3 {
  margin-bottom: 5px;
  font-size: 14px;
}

p {
  font-size: 12px;
  margin: 0;
}

.enter-shop-btn {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  padding: 1rem 2rem;
  border-radius: 5px;
  background-color: #04AA6D;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.enter-shop-btn:hover {
  background-color: #28a745;
}

.door-wrapper:hover .door-front {
  transform: translateY(-100%);
}

.contact-info {
  font-size: 20pt;
}

@media (max-width: 768px) {
  .door-wrapper {
    width: 80%;
    text-shadow: #000000;
  }

  .service {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
</style>
