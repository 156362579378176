<template>
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="service">
          <div class="service-icon">
            <i class="fas fa-cog"></i>
          </div>
          <h3>Engine Overhaul</h3>
          <p>Our experienced technicians can perform a complete engine overhaul, replacing worn-out components and
            ensuring your diesel engine runs smoothly and efficiently.</p>
        </div>
      </div>
      <div class="carousel-item">
        <div class="service">
          <div class="service-icon">
            <i class="fas fa-tachometer-alt"></i>
          </div>
          <h3>Turbocharger Repair</h3>
          <p>We specialize in turbocharger repair and replacement, helping you regain the power and performance your
            diesel vehicle deserves.</p>
        </div>
      </div>
      <div class="carousel-item">
        <div class="service">
          <div class="service-icon">
            <i class="fas fa-gas-pump"></i>
          </div>
          <h3>Fuel System Maintenance</h3>
          <p>Our fuel system maintenance services ensure your diesel engine's fuel system is clean and functioning at
            its best, improving fuel efficiency and reducing emissions.</p>
        </div>
      </div>
      <div class="carousel-item">
        <div class="service">
          <div class="service-icon">
            <i class="fas fa-smog"></i>
          </div>
          <h3>Diesel Exhaust Fluid (DEF) Services</h3>
          <p>We offer DEF system maintenance and repair, ensuring your diesel vehicle meets emissions standards while
            maintaining optimal performance.</p>
        </div>
      </div>
      <div class="carousel-item">
        <div class="service">
          <div class="service-icon">
            <i class="fas fa-laptop-code"></i>
          </div>
          <h3>Electronic Diagnostics</h3>
          <p>Our advanced electronic diagnostic tools allow us to quickly identify and repair issues with your diesel
            vehicle's engine management system.</p>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
export default {
  // No need for a setup method here
};
</script>

<style scoped>
.carousel-inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service {
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.service-icon {
  font-size: 30px;
  color: #007bff;
  margin-bottom: 10px;
}

h3 {
  margin-bottom: 10px;
  font-size: 24px;
}

p {
  font-size: 16px;
}

/* Add more styles as needed */
</style>
