<template>
  <div class="container-fluid bg-color ">
    <section class="row justify-content-between">
      <p class=" col-md-3 text-color mt-2">Copyright Steele Services LLC</p>
      <p class="col-md-3 text-color mt-2">Designed/Powered by: Vassar & Smith Technologies</p>
    </section>

  </div>
</template>


<script>

export default {
  setup() {
    return {}
  }
};
</script>


<style scoped>
.bg-color {
  background-color: #303c4c;
}

.text-color {
  color: #ffffff;
}
</style>