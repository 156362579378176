<template>
  <div id="about" class="container-fluid">
    <meta name="description"
      content="Welcome to our mechanic shop! Learn more about our team of skilled professionals and our mission to keep your vehicles running smoothly.">
    <h1 class="text-center text-white my-4">About Us</h1>
    <div class="row justify-content-center">
      <div class="col-md-4 mb-4">
        <div class="card">
          <img class="card-img-top" src="../assets/placeholder.jpg" alt="John Doe">
          <div class="card-body">
            <h5 class="card-title">John Doe</h5>
            <p class="card-text">Lead Mechanic</p>
            <p class="card-text">John is a certified automotive technician with over 10 years of experience. His
              expertise lies in complex diagnostics and engine repairs.</p>
            <a href="#" class="btn btn-primary">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="card">
          <img class="card-img-top" src="../assets/placeholder.jpg" alt="Jane Smith">
          <div class="card-body">
            <h5 class="card-title">Jane Smith</h5>
            <p class="card-text">Transmission Specialist</p>
            <p class="card-text">Jane has a passion for all things transmission. She is certified by the Automatic
              Transmission Rebuilders Association (ATRA).</p>
            <a href="#" class="btn btn-primary">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="card">
          <img class="card-img-top" src="../assets/placeholder.jpg" alt="Michael Johnson">
          <div class="card-body">
            <h5 class="card-title">Michael Johnson</h5>
            <p class="card-text">Wheel Alignment Technician</p>
            <p class="card-text">Michael ensures your vehicle is safe and drives straight with his precision wheel
              alignment skills.</p>
            <a href="#" class="btn btn-primary">Read More</a>
          </div>
        </div>
      </div>
    </div>
    <p class="text-center text-white mt-4">At {{ companyName }}, we are a team of passionate mechanics dedicated to
      providing top-notch service for all your automotive needs. Our experienced technicians have years of knowledge and
      expertise in various vehicle makes and models, ensuring your car is in the best hands. Whether it's a routine
      maintenance, complex repair, or advanced diagnostics, we take pride in delivering exceptional quality and value
      for your money. </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyName: 'Steele Auto Repair',
    };
  },
  mounted() {
    // Add any additional setup logic here
  },
};
</script>

<!-- Enhance SEO by using meaningful headings and optimizing the page -->
<style scoped>
#about {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding: 6rem;
}

.card-img-top {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card-body {
  height: 250px;
}

@media (max-width: 768px) {
  .card-body {
    padding: .5rem;
  }

  h1 {
    font-size: 1.8rem;
  }
}
</style>