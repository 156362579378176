<template>
  <nav class="navbar navbar-expand-md p-0">
    <div class="container-fluid p-0">
      <div class="w-100 d-flex justify-content-center">
        <h1 class="text-center text-uppercase w-100 my-4">Steele Auto Repair</h1>
        <!-- <button class="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link pointer text-center" to="/"><span
                  class="text-color button-border pb-0 mx-1 underline" data-bs-target="#navbarNav"
                  data-bs-toggle="collapse">Home</span></router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link pointer text-center" to="/about"><span
                  class="text-color button-border pb-0 mx-1 underline">About</span></router-link>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  setup() {
    return {
      hoverEffect: false,
      clickEffect: false
    }
  }
};
</script>

<style scoped>
.logo {
  height: 13vh;
}

.navbar {
  height: auto;
}

.navbar-mine {
  border-bottom: 4px solid black;
}

.button-style-two {
  border: 2px solid #08494B;
  z-index: 3;
  transition: all 0.3s ease;
  /* Smooth transition for effects */
}

/* Hover effects */
.button-style-two:hover {
  background-color: #08494B;
  /* Darken background on hover */
  color: rgb(255, 255, 255);
  /* Change text color to white */
  transform: translateY(-2px);
  /* Subtle lift on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Add a shadow */
}

/* Active effect (when clicked) */
.button-style-two:active {
  transform: translateY(0);
  /* Remove lift on click */
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  /* Reduce shadow */
}

/* Underline effect for navigation links */
.nav-link .underline {
  border-bottom: 2px solid transparent;
  /* Initial state: no underline */
  transition: border-color 0.3s ease;
}

.nav-link:hover .underline {
  border-color: #08494B;
  /* Add underline on hover */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  /* Adjust breakpoint as needed */

  /* Stack Logo and Text */
  .navbar .row:first-child {
    flex-direction: column;
    /* Stack elements vertically */
    align-items: center;
  }

  /* Stack Nav Links */
  .navbar-nav {
    flex-direction: column;
  }

  /* Center Contact Button */
  .btn {
    margin: 1rem auto;
    /* Center button */
  }

  /* Adjust logo size */
  .logo {
    height: 10vh;
    /* Or use max-width for a more flexible approach */
  }
}
</style>
